var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-void-ticket",
      "title": _vm.isExternalTickets ? _vm.$t('reservation.voidTicket.titleExternal') : _vm.$t('reservation.voidTicket.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "size": "lg",
      "body-class": "p-50",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.handleModalOpen
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), !_vm.disabledVoidForQH ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "disabled": !_vm.indexSelectedTicketToVoid.length || _vm.lockVoid || _vm.checkFirstDateIssueForQHDifToday,
            "pill": ""
          },
          on: {
            "click": _vm.submitVoidTicket
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.voidTicket.title')))])]) : _vm._e()];
      }
    }])
  }, [_vm.disabledVoidForQH ? _c('div', [_vm._v(" Không còn hành trình để thực hiện void. ")]) : _c('div', [_c('b-card', {
    attrs: {
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "text-heading-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.voidTicket.selectTicketToVoid')) + ": ")]), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [!_vm.isEmpty(_vm.getEticketArrAvailToVoid(_vm.ticketList)) && _vm.getEticketArrAvailToVoid(_vm.ticketList).length > 1 ? _c('b-form-checkbox', {
          staticClass: "py-0 pt-50",
          attrs: {
            "aria-describedby": "etickets",
            "aria-controls": "etickets",
            "disabled": _vm.isVoidAllEticket || _vm.disableCheckAll(_vm.ticketList)
          },
          on: {
            "change": _vm.toggleAll
          },
          model: {
            value: _vm.allSelected,
            callback: function callback($$v) {
              _vm.allSelected = $$v;
            },
            expression: "allSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2 font-weight-bold text-dark",
          staticStyle: {
            "padding-bottom": "6px"
          }
        }, [_vm._v(" " + _vm._s(_vm.allSelected ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "etickets",
            "stacked": ""
          },
          model: {
            value: _vm.indexSelectedTicketToVoid,
            callback: function callback($$v) {
              _vm.indexSelectedTicketToVoid = $$v;
            },
            expression: "indexSelectedTicketToVoid"
          }
        }, _vm._l(_vm.ticketList, function (ticket, indexTicket) {
          return _c('div', {
            key: indexTicket,
            staticClass: "d-flex-center justify-content-start py-0 pb-50 ml-50 ml-lg-75 w-100"
          }, [_c('b-form-checkbox', {
            attrs: {
              "value": indexTicket,
              "disabled": _vm.disableEticket(ticket) || _vm.isVoidAllEticket
            }
          }, [_c('div', {
            staticClass: "d-flex flex-wrap font-medium-1 fw-700"
          }, [_c('div', [_c('span', {
            staticClass: "text-warning"
          }, [_vm._v(_vm._s(ticket.number))]), _c('b-badge', {
            staticClass: "px-50 ml-50 rounded-lg fw-700",
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(" " + _vm._s(ticket.ticketType) + " ")]), ticket.status ? _c('span', {
            class: "ml-25 ".concat(['OK', 'OPEN', 'EMD'].includes(ticket.status) ? 'text-success' : 'text-warning')
          }, [_vm._v(" - " + _vm._s(ticket.status) + " ")]) : _vm._e()], 1), _c('div', {
            staticClass: "mx-50"
          }, [_vm._v(" " + _vm._s(ticket.passengerPaxId ? "(".concat(ticket.passengerPaxId, ")") : '') + " " + _vm._s(ticket.passengerName)), _c('br')]), _c('div', [['OK', 'CTRL', 'OPEN', 'EMD'].includes(ticket.status) && _vm.bookingData.source !== 'QH' ? _c('code', {
            staticClass: "ml-25 pt-25"
          }, [_vm._v(" " + _vm._s(_vm.$t('reservation.voidTicket.paymentTime')) + ": " + _vm._s(_vm.convertISODateTime(ticket.dateIss).date) + " ")]) : _vm._e()])])])], 1);
        }), 0)];
      }
    }])
  })], 1), ['VN1A', 'VN1A_MT'].includes(_vm.bookingData.source) ? _c('div', {
    staticClass: "mt-1 shadow"
  }, [_c('b-alert', {
    staticClass: "mb-50 p-50",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "text-danger fw-700"
  }, [_vm._v(" Lưu ý: "), _c('br'), _vm._v(" - Số vé hoàn sẽ không void được. ")])])], 1) : _vm._e(), !!_vm.lockVoid ? _c('b-card', [_c('b-alert', {
    staticClass: "p-1 py-50",
    attrs: {
      "variant": "danger",
      "show": !!_vm.lockVoid
    }
  }, [_vm._v(" " + _vm._s(_vm.lockVoid) + " " + _vm._s(_vm.$t('reservation.voidTicket.lockVoid')) + " ")])], 1) : _vm._e(), ['QH'].includes(_vm.bookingData.source) ? _c('div', [_vm.checkFirstDateIssueForQHDifToday ? _c('b-alert', {
    staticClass: "p-1 mb-1 font-weight-bolder text-danger",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_vm._v(" Không thể void vé vì ngày xuất vé khác ngày hiện tại!!! ")]) : _vm._e(), _c('b-alert', {
    staticClass: "p-1 mb-0 font-weight-bolder text-airline",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', [_vm._v("Bamboo Airways:")]), _c('div', [_vm._v(" - " + _vm._s(_vm.$t('reservation.voidTicket.QH_void1')))]), _c('div', [_vm._v(" - " + _vm._s(_vm.$t('reservation.voidTicket.QH_void2')))])])], 1) : _vm._e(), ['1S', '1S_CTRL', '1G'].includes(_vm.bookingData.source) ? _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [['1S', '1S_CTRL'].includes(_vm.bookingData.source) ? _c('b-alert', {
    staticClass: "p-1 mb-0 font-weight-bolder text-airline",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', [_vm._v("Vietnam Airlines: - " + _vm._s(_vm.$t('reservation.voidTicket.VN_void1')))]), _c('div', [_vm._v("- " + _vm._s(_vm.$t('reservation.voidTicket.VN_void')))])]) : _vm._e(), ['1G'].includes(_vm.bookingData.source) ? _c('b-alert', {
    staticClass: "p-1 mb-0 font-weight-bolder text-airline",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', [_vm._v("- " + _vm._s(_vm.$t('reservation.voidTicket.VN_void1')))])]) : _vm._e()], 1) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }