<template>
  <b-modal
    id="modal-void-ticket"
    :title="isExternalTickets ? $t('reservation.voidTicket.titleExternal') : $t('reservation.voidTicket.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    size="lg"
    body-class="p-50"
    no-close-on-backdrop
    @show="handleModalOpen"
  >
    <div v-if="disabledVoidForQH">
      Không còn hành trình để thực hiện void.
    </div>
    <div v-else>
      <b-card body-class="p-50">
        <div class="text-heading-4">
          {{ $t('reservation.voidTicket.selectTicketToVoid') }}:
        </div>

        <b-form-group>
          <template #label>
            <b-form-checkbox
              v-if="!isEmpty(getEticketArrAvailToVoid(ticketList)) && getEticketArrAvailToVoid(ticketList).length > 1"
              v-model="allSelected"
              aria-describedby="etickets"
              aria-controls="etickets"
              class="py-0 pt-50"
              :disabled="isVoidAllEticket || disableCheckAll(ticketList)"
              @change="toggleAll"
            >
              <div
                class="text-body-2 font-weight-bold text-dark"
                style="padding-bottom: 6px"
              >
                {{ allSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
              </div>
            </b-form-checkbox>
          </template>

          <template #default="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="indexSelectedTicketToVoid"
              :aria-describedby="ariaDescribedby"
              name="etickets"
              stacked
            >
              <div
                v-for="(ticket, indexTicket) in ticketList"
                :key="indexTicket"
                class="d-flex-center justify-content-start py-0 pb-50 ml-50 ml-lg-75 w-100"
              >
                <b-form-checkbox
                  :value="indexTicket"
                  :disabled="disableEticket(ticket) || isVoidAllEticket"
                >
                  <div class="d-flex flex-wrap font-medium-1 fw-700">
                    <div>
                      <span class="text-warning">{{ ticket.number }}</span>
                      <b-badge
                        variant="info"
                        class="px-50 ml-50 rounded-lg fw-700"
                      >
                        {{ ticket.ticketType }}
                      </b-badge>
                      <span
                        v-if="ticket.status"
                        :class="`ml-25 ${['OK', 'OPEN', 'EMD'].includes(ticket.status) ? 'text-success' : 'text-warning'}`"
                      >
                        - {{ ticket.status }}
                      </span>
                    </div>

                    <div class="mx-50">
                      {{ ticket.passengerPaxId ? `(${ticket.passengerPaxId})` : '' }} {{ ticket.passengerName }}<br>
                    </div>

                    <div>
                      <code
                        v-if="['OK', 'CTRL','OPEN','EMD'].includes(ticket.status) && bookingData.source !== 'QH'"
                        class="ml-25 pt-25"
                      >
                        {{ $t('reservation.voidTicket.paymentTime') }}: {{ convertISODateTime(ticket.dateIss).date }}
                      </code>
                    </div>
                  </div>
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </b-card>

      <div
        v-if="['VN1A', 'VN1A_MT'].includes(bookingData.source)"
        class="mt-1 shadow"
      >
        <b-alert
          show
          variant="warning"
          class="mb-50 p-50"
        >
          <div class="text-danger fw-700">
            Lưu ý: <br>
            - Số vé hoàn sẽ không void được.
          </div>
        </b-alert>
      </div>

      <b-card v-if="!!lockVoid">
        <b-alert
          variant="danger"
          :show="!!lockVoid"
          class="p-1 py-50"
        >
          {{ lockVoid }} {{ $t('reservation.voidTicket.lockVoid') }}
        </b-alert>
      </b-card>

      <div v-if="['QH'].includes(bookingData.source)">
        <b-alert
          v-if="checkFirstDateIssueForQHDifToday"
          variant="danger"
          show
          class="p-1 mb-1 font-weight-bolder text-danger"
        >
          Không thể void vé vì ngày xuất vé khác ngày hiện tại!!!
        </b-alert>
        <b-alert
          variant="warning"
          show
          class="p-1 mb-0 font-weight-bolder text-airline"
        >
          <div>Bamboo Airways:</div>
          <div> - {{ $t('reservation.voidTicket.QH_void1') }}</div>
          <div> - {{ $t('reservation.voidTicket.QH_void2') }}</div>
        </b-alert>
      </div>

      <b-card
        v-if="['1S', '1S_CTRL', '1G'].includes(bookingData.source)"
        no-body
      >
        <b-alert
          v-if="['1S', '1S_CTRL'].includes(bookingData.source)"
          variant="warning"
          show
          class="p-1 mb-0 font-weight-bolder text-airline"
        >
          <div>Vietnam Airlines: - {{ $t('reservation.voidTicket.VN_void1') }}</div>
          <div>- {{ $t('reservation.voidTicket.VN_void') }}</div>
          <!-- <div> - {{ $t('reservation.voidTicket.VN_void2', {time: '1'}) }}</div>
          <div> - {{ $t('reservation.voidTicket.VN_void2', {time: '6'}) }}</div> -->
        </b-alert>
        <b-alert
          v-if="['1G'].includes(bookingData.source)"
          variant="warning"
          show
          class="p-1 mb-0 font-weight-bolder text-airline"
        >
          <div>- {{ $t('reservation.voidTicket.VN_void1') }}</div>
        </b-alert>
      </b-card>
    </div>

    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-if="!disabledVoidForQH"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient mt-lg-25  border-0"
        :disabled="!indexSelectedTicketToVoid.length || lockVoid || checkFirstDateIssueForQHDifToday"
        pill
        @click="submitVoidTicket"
      >
        <span class="align-middle">{{ $t('reservation.voidTicket.title') }}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BCard, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BButton, BAlert, BBadge,
} from 'bootstrap-vue'
import {
  ref, computed, watch, toRefs,
} from '@vue/composition-api'
import uniq from 'lodash/uniq'
import isEmpty from 'lodash/isEmpty'

import { isDomesticFlight } from '@/constants/selectOptions'

import {
  convertISODateTime,
} from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal, BCard, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BButton, BAlert, BBadge,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
    ticketList: {
      type: Array,
      default: () => [],
    },
    isExternalTickets: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    // Hiện tại QH void ticket là sẽ void toàn bộ ticket của 1 PNR
    // ========================================================
    const indexSelectedTicketToVoid = ref([])
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    const indexSelectedTicketToVoidComputed = computed(() => indexSelectedTicketToVoid.value.sort())
    const currentTime = ref(new Date().toISOString())

    const { voidTicket } = useReservationHandle()
    const { ticketList, bookingData, isExternalTickets } = toRefs(props)

    // nếu ngày hiện tại khác firstDateIssue thì ko cho void QH (chỉ dùng cho QH vì QH void toàn bộ vé)
    // (https://discord.com/channels/1054696448110903327/1237682876137340978)
    const checkFirstDateIssueForQHDifToday = computed(() => {
      const firstDateIssue = bookingData.value?.firstDateIssue
      const firstDateIss = convertISODateTime(firstDateIssue).date
      const currentTime = new Date()
      const today = convertISODateTime(currentTime).date
      return bookingData.value.source === 'QH' && today !== firstDateIss
    })
    // check phải void hết các vé trong booking hay ko ? (QH và 1G_NDC)
    const isVoidAllEticket = computed(() => ['QH'].includes(bookingData.value.source) || (bookingData.value.source === '1G' && bookingData.value.extra.some(item => item.contentSource === 'NDC')))

    // nếu ko có itineraries hoặc itineraries.length = 0 thì ko cho void nhé (https://discord.com/channels/1054696448110903327/1237613926993363008/1237614743687270461)
    const disabledVoidForQH = computed(() => bookingData.value.source === 'QH' && !bookingData.value?.itineraries?.length)

    function checkPassDayVoid(dateIss) {
      const currentDate = convertISODateTime(currentTime.value).dateFilter
      const issTime = convertISODateTime(dateIss).ISOdatetime
      const issDate = issTime.split('T')[0]

      return issDate !== currentDate
    }

    function disableCheckAll(arrTicket) {
      if (['VN1A_MT', 'VN1A'].includes(bookingData.value?.source)) {
        return isEmpty(arrTicket.filter(t => ['OPEN', 'EMD'].includes(t.status) && !checkPassDayVoid(t.dateIss)))
      }
      return false
    }

    function disableEticket(eticket) {
      if (['VN1A_MT', 'VN1A'].includes(bookingData.value.source)) {
        if (!['OPEN', 'EMD'].includes(eticket.status)) return true
        return checkPassDayVoid(eticket.dateIss)
      }

      // if (isVoidAllEticket.value) return true
      if (!['OK', 'CTRL'].includes(eticket.status) && !['VN1A_MT', 'VN1A'].includes(bookingData.value.source)) return true

      // Vé hoàn RFD trạng thái ok thì check số vé gốc đã được VOID chưa ?
      if (['RFD'].includes(eticket.ticketType) && ['OK'].includes(eticket.status) && !isExternalTickets.value) {
        const originalEticket = ticketList.value.find(item => item.number === eticket.originalTicketDocument)
        if (!originalEticket) return true
        // if (['VOID'].includes(originalEticket.status)) return true
      }

      const currentDate = convertISODateTime(currentTime.value).dateFilter
      const issTime = convertISODateTime(eticket.dateIss).ISOdatetime // Time GMT
      const issDate = issTime.split('T')[0] // timeISO

      if (['1S', '1S_CTRL', '1G'].includes(bookingData.value.source)) {
        if (issDate !== currentDate) {
          return true
        }

        if (issDate === currentDate && eticket?.segments.length > 0 && bookingData.value.source.includes('1S')) {
          const isDomestic = eticket?.segments.every(item => isDomesticFlight(item.departure, item.arrival))
          const departTime = new Date(eticket?.segments[0]?.departureDate).getTime()
          const curTime = new Date().getTime()
          const HOUR = 60 * 60 * 1000

          if (
            // (isDomestic && !(departTime - curTime > 1 * HOUR)) ||
            (!isDomestic && !(departTime - curTime > 6 * HOUR))
          ) {
            return true
          }
        }
      }

      return false
    }

    function getEticketArrAvailToVoid(ticketArr) {
      const result = ['1S', '1S_CTRL', '1G'].includes(bookingData.value.source)
        ? ticketArr.filter(item => !disableEticket(item))
        : ticketArr
      return result
    }

    // ANCHOR SELECT ALL
    const allSelected = ref(false)

    function toggleAll(checked) {
      let result = []
      if (['VN1A_MT', 'VN1A'].includes(bookingData.value.source)) {
        if (checked) {
          indexSelectedTicketToVoid.value = getEticketArrAvailToVoid(ticketList.value)
            .map((item, index) => ({ ...item, indexSelect: index }))
            .filter(t => ['OPEN', 'EMD'].includes(t.status) && !checkPassDayVoid(t.dateIss))
            .map(e => e.indexSelect)
        } else {
          indexSelectedTicketToVoid.value.splice(0, indexSelectedTicketToVoid.value.length)
        }
        return
      }

      if (['1S', '1S_CTRL', '1G'].includes(bookingData.value.source)) {
        if (checked) {
          result = ticketList.value.map((item, index) => {
            if (disableEticket(item)) {
              return
            }
            // eslint-disable-next-line consistent-return
            return index
          }).slice().filter(i => i !== undefined && i !== null)
        } else {
          result = []
        }
      } else if (isVoidAllEticket.value) {
        result = getEticketArrAvailToVoid(ticketList.value).map((e, eIndex) => eIndex).slice().filter(i => i !== undefined && i !== null)
      }
      indexSelectedTicketToVoid.value = result
    }

    watch(() => indexSelectedTicketToVoid.value, newValue => {
      const isAllAvailTicketSelected = (newValue.length === (['VN1A_MT', 'VN1A'].includes(bookingData.value?.source)
        ? getEticketArrAvailToVoid(ticketList.value)
          .filter(t => ['OPEN', 'EMD'].includes(t.status))
          .map((_, index) => index).length
        : getEticketArrAvailToVoid(ticketList.value).map((item, index) => index).length))

      if (newValue.length === 0) {
        allSelected.value = false
      } else if (isAllAvailTicketSelected) {
        allSelected.value = true
      } else {
        allSelected.value = false
      }
    })

    const lockVoid = computed(() => {
      if (bookingData.value.airlines.includes('K6')) return 'K6'
      if (
        bookingData.value.airlines.includes('PR')
        && new Date(bookingData.value.itineraries[0][0].departure.at.toString()).getTime() - new Date().getTime() < 24 * 60 * 60 * 1000) {
        return 'PR'
      }
      return false
    })

    function submitVoidTicket(bvModalEvent) {
      this.$bvModal.show('modal-api-loading')
      bvModalEvent.preventDefault()

      const payload = {
        contact: bookingData.value.paxContact.id,
        source: bookingData.value.source,
        agencyCode: bookingData.value.agency,
        paxCode: bookingData.value.paxContact.code,
        pnrNumber: bookingData.value.bookingCode,
        ticketNumbers: uniq(ticketList.value
          .filter((ticket, ticketIndex) => indexSelectedTicketToVoidComputed.value.includes(ticketIndex))
          .map(ticket => ticket.number)),
      }

      voidTicket(payload)
        .then(() => {
          // this.$bvModal.show('modal-void-ticket-cancel-alert')
        })
        .finally(() => {
          this.$bvModal.hide('modal-void-ticket')
          this.$bvModal.hide('modal-api-loading')
        })
    }

    function handleModalOpen() {
      if (['1S', '1S_CTRL', '1G'].includes(bookingData.value.source)) {
        indexSelectedTicketToVoid.value = []
      } else { // QH
        indexSelectedTicketToVoid.value = getEticketArrAvailToVoid(ticketList.value).map((e, i) => i).slice().filter(i => i !== undefined && i !== null)
      }
    }

    watch([ticketList, bookingData], ([ticketVal, bookingVal]) => {
      if (!isEmpty(ticketVal) && bookingVal && isVoidAllEticket.value) {
        indexSelectedTicketToVoid.value = getEticketArrAvailToVoid(ticketList.value).map((e, i) => i)
      }
    }, { deep: true })

    return {
      disableEticket,
      submitVoidTicket,
      convertISODateTime,
      getEticketArrAvailToVoid,
      indexSelectedTicketToVoid,
      allSelected,
      toggleAll,
      lockVoid,
      isEmpty,
      handleModalOpen,
      isVoidAllEticket,
      disableCheckAll,
      disabledVoidForQH,
      checkFirstDateIssueForQHDifToday,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
